<template>
    <v-navigation-drawer
      v-if="!isMobile && !isLogin && load"
      color="primary"
      :mini-variant.sync="mini"
      permanent
      right
      app
      class="fonts"
    >
      
      <v-list >
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-app-bar-nav-icon @click.stop="mini = !mini"></v-app-bar-nav-icon>
            </v-list-item-avatar>
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="text-h6 white--text">
                {{name}}
              </v-list-item-title>
              <v-list-item-subtitle></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list
          nav
          dense
        >
          <v-list-item v-for="item in menu" :key="item.route" link :to="item.route">
            <v-list-item-icon>
              <v-icon color="white">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">{{item.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import appmixns from './appmixins.vue'
export default {
  name: 'Sidebar',
  components: {
  },
  mixins: [appmixns],
  data: () => ({
    mini: true,
  }),
  computed: {
  },
  methods:{
  },
  watch: {
    },
};
</script>
