<template>
  <div class="home">
    
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  computed: {
      loggedIn() {
        return this.$store.state.auth.fstatus.loggedIn;
      },
      rule() {
        return this.$store.state.auth.user.user_role;
      }
    },
    created() {
      if (this.loggedIn) {
        switch (this.rule) {
          case 'OWNER':
            this.$router.push('/reguser');
            break;
          case 'SuperAdmin':
            this.$router.push('/userlist');
            break;
          case 'FinancialManager':
            this.$router.push('/financialcheck');
            break;
          case 'SocialOperator':
            this.$router.push('/InvoceAdd');
            break;
          case 'PackingOperator':
            this.$router.push('/packingcheck');
            break;
          case 'SendingOperator':
            this.$router.push('/sendingcheck');
            break;
          case 'DeliveryOperator':
            this.$router.push('/deliverycheck');
            break;
          case 'AccountingOperator':
            this.$router.push('/accountingcheck');
            break;
          case 'MonitoringOperator':
            this.$router.push('/orderstate');
            break;
          case 'StockClerk':
            this.$router.push('/sendingcheck');
            break;
          default:
            this.$router.push('/404');
        }
        // if(this.rule === '"SocialOperator"')
        //   this.$router.push('/InvoceAdd');
        // else
      }
    },
}
</script>
