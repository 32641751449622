import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
  },
  state: {
    sideActive: false,
    menu: [{
      icon: 'mdi-home',
      route: '/',
      name: 'خانه',
    }],
    // snakeMessage: ''
  },
  mutations: {
    setSidebar(state, payload) {
      state.sideActive = true
      state.menu = payload
    },
    RemoveSidebar(state) {
      state.sideActive = false
      state.menu = [{
        icon: 'mdi-home',
        route: '/',
        name: 'خانه',
      }]
    },
  },
  actions: {
  },
})
