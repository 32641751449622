<script>
import sidebar from '../../data/sidebar'

export default {
  name: 'Sidebar',
  components: {
  },
  data: () => ({
    sidebars: sidebar,
    menu : [],
    load: false
  }),
  computed: {
    isLogin() {
      return this.$route.name === 'Login'
    },
    name() {
      if(this.$store.state.auth.user !== null){
        return this.$store.state.auth.user.user_name;
      }
      return "empty"
    },
    changeRole(){
      if(this.$store.state.auth.user)
        return this.$store.state.auth.user.user_role
      else 
        return false
    }
  },
  methods:{
    isAdmin(){
      if(this.$store.state.auth.user !== null){
        if (this.$store.state.auth.user.user_role === "FinancialManager")
          this.load = true
        else if(this.$store.state.auth.user.user_role === "SuperAdmin"){
          this.load = true
        }else if(this.$store.state.auth.user.user_role === "SocialOperator"){
          this.load = true
        }else if(this.$store.state.auth.user.user_role === "MonitoringOperator"){
          this.load = true
        }else if(this.$store.state.auth.user.user_role === "StockClerk"){
          this.load = true
        }else
          this.load = false 
      }
      
    },
    menus(){
      if(this.$store.state.auth.user !== null){
        if (this.$store.state.auth.user.user_role === "FinancialManager") {
          this.menu = this.sidebars.menu_FinancialManager
        } else if (this.$store.state.auth.user.user_role === "SuperAdmin") {
          this.menu = this.sidebars.menu_SuperAdmin
        } else if (this.$store.state.auth.user.user_role === "SocialOperator") {
          this.menu = this.sidebars.menu_SocialAdmin
        } else if (this.$store.state.auth.user.user_role === "MonitoringOperator") {
          this.menu = this.sidebars.menu_MonitoringOperator
        } else if (this.$store.state.auth.user.user_role === "StockClerk") {
          this.menu = this.sidebars.menu_StockClerk
        } else {
          this.menu = this.sidebars.menu_MonitoringOperator
        }
      }
    }
  },
  watch: {
      // group () {
      //   this.drawer = false
      // },
       changeRole: {
        immediate: true,
        async handler() {
          await this.isAdmin()
          await this.menus()
        },
      },
    },
};
</script>
