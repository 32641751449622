<template>
  <v-footer
      v-if="isMobile && !isLogin && load"
      padless
      fixed
      app
      class="mt-15"
    >
      <hr/>
      <v-card
        flat
        tile
        style="overflow-x:auto; white-space: nowrap;"
        class="text-center scrollx"
      >
        <v-card-text>
          <v-btn
            v-for="icon in menu"
            :key="icon.route"
            class="mx-2"
            text
            :to="icon.route"
          >
            <span  class="f10 weight-700 fonts">{{icon.nameM}}</span>
            <v-icon class="px-1" size="16px">
              {{ icon.icon }}
            </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
</template>

<script>
import appmixns from './appmixins.vue'
export default {
  name: 'Footer',
  components: {
  },
  mixins: [appmixns],
  data: () => ({
  }),
  computed: {
  },
  methods:{
  },
  watch: {
    },
};
</script>

<style>
.mt30{
  margin-top: 50px !important; 
}
.f10{
  font-size: 12px;
}
.scrollx {
   overflow-x: scroll
}
</style>