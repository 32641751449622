<template>
  <v-app>
    
    <sidebarComponent></sidebarComponent>
    <v-app-bar
      v-if="!isLogin"
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 "
          contain
          min-width="100"
          src="https://cdn.missha-ir.com/frontend/img/logo/logo.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        @click="logout()"
        text
      >
        <span class="mr-2 fonts hidden-sm-and-down">خروج</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="mb30">
      <router-view/>
      <!-- <messageShow :active="$store.state.snakeActive" :color="$store.state.snakeColor" :messages="$store.state.snakeMessage"></messageShow> -->
    </v-main>
    <footerComponent class="mt-15"></footerComponent>
  </v-app>
</template>

<script>
import sidebarComponent from './components/global/sidebar.vue'
import footerComponent from './components/global/footer.vue'

export default {
  name: 'App',
  components: {
    sidebarComponent,
    footerComponent
  },
  data: () => ({
  }),
  computed: {
    isLogin() {
      return this.$route.name === 'Login'
    }
  },
  methods:{
    logout (){
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },
  },
  watch: {
    },
};
</script>
<style scoped>
.mb30{
  margin-bottom: 70px !important;
}
</style>
