import axios from 'axios';

const API_URL = process.env.VUE_APP_BASE_URL  //'http://138.201.77.42:8081/api/user/'

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/login', {
        username: user.name,
        password: user.password
      })
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    localStorage.removeItem('user')
  }
}
export default new AuthService()