import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'میشا | خانه' }
  },
  {
    path: '/reguser',
    name: 'regUser',
    meta: { title: 'میشا | ایجاد کاربر' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/usercreate',
    name: 'UserCreate',
    meta: { title: 'میشا | ایجاد کاربر' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserCreate.vue')
  },
  {
    path: '/userlist',
    name: 'َUserList',
    meta: { title: 'میشا | مدیریت کاربران' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserList.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'میشا | ورود' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/invoceAdd',
    name: 'InvoceAdd',
    meta: { title: 'میشا | اضافه کردن سفارش' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InvoceAdd.vue')
  },
  {
    path: '/financialcheck',
    name: 'FinancialCheck',
    meta: { title: 'میشا | تایید مالی' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FinancialCheck.vue')
  },
  {
    path: '/packingcheck',
    name: 'PackingCheck',
    meta: { title: 'میشا |  بسته بندی' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PackingCheck.vue')
  },
  {
    path: '/sendingcheck',
    name: 'SendingCheck',
    meta: { title: 'میشا |   ارسال' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SendingCheck.vue')
  },
  {
    path: '/deliverycheck',
    name: 'DeliveryCheck',
    meta: { title: 'میشا |  تحویل مشتری' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DeliveryCheck.vue')
  },
  {
    path: '/test',
    name: 'Test',
    meta: { title: 'میشا | آزمایشی' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Test.vue')
  },
  {
    path: '/accountingcheck',
    name: 'AccountingCheck',
    meta: { title: 'میشا |  تایید نهایی' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountingCheck.vue')
  },
  {
    path: '/ordertracking',
    name: 'OrderTracking',
    meta: { title: 'میشا | رهگیری سفارش' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderTracking.vue')
  },
  {
    path: '/rejectedorder',
    name: 'RejectedOrder',
    meta: { title: 'میشا |  سفارش های رد شده ' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RejectedOrder.vue')
  },
  {
    path: '/orderstate',
    name: 'OrderState',
    meta: { title: 'میشا | وضعیت سفارش' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderState.vue')
  },
  {
    path: '/orderstate/:id',
    name: 'OrderStateId',
    meta: { title: 'میشا |  گزارش سفارش' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderStateDetails.vue')
  },
  {
    path: '/opratorreport',
    name: 'OpratorReport',
    meta: { title: 'میشا |  گزارش عملکرد اپراتورها' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OpratorReport.vue')
  },
  {
    path: '/productreport',
    name: 'ProductReport',
    meta: { title: 'میشا |  گزارش فروش محصولات' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductReport.vue')
  },
  {
    path: '/jobmisshafail',
    name: 'JobMisshaFail',
    meta: { title: 'میشا |   لیست سفارشات ثبت نشده در حسابدار' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/JobMisshaFail.vue')
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: { title: 'میشا | 404 ' },
    component: () => import('../views/NotFound.vue')
  },
  {
    path: '/accessdenied',
    name: 'AccessDenied',
    meta: { title: 'میشا | 403' },
    component: () => import('../views/AccessDenied.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/404'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});
const DEFAULT_TITLE = 'میشا';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
export default router
