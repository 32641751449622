<script>
export default {
  data() {
    return {
      
    //   msg: 'Hello World',
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mobile
    }
  },
  created: function () {
    // console.log('Printing from the Mixin')
  },
  methods: {
  },
}
</script>
