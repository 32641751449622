export default
    {
      menu_SuperAdmin: [
        {
          icon: 'mdi-account-multiple-plus',
          route: '/userlist',
          name: 'مدیریت کاربران ',
          nameM: 'کاربر '
        },
        {
          icon: 'mdi-briefcase-check',
          route: '/financialcheck',
          name: 'تایید مالی',
          nameM: 'مالی '
        },
        {
          icon: 'mdi-playlist-check',
          route: '/packingcheck',
          name: 'تایید بسته بندی',
          nameM: 'بسته بندی '
        },
        {
          icon: 'mdi-truck-check',
          route: '/SendingCheck',
          name: 'تایید ارسال',
          nameM: 'ارسال '
        },
        {
          icon: 'mdi-phone-check',
          route: '/deliverycheck',
          name: 'تایید تحویل مشتری',
          nameM: 'تحویل '
        },{
          icon: 'mdi-database-check',
          route: '/accountingcheck',
          name: 'تایید نهایی',
          nameM: 'نهایی '
        },
        {
          icon: 'mdi-toy-brick-search-outline',
          route: '/orderstate',
          name: 'وضعیت سفارش',
          nameM: 'وضعیت سفارش '
        },
        {
          icon: 'mdi-tray-remove',
          route: '/jobmisshafail',
          name: ' سفارشات ثبت نشده',
          nameM: ' ثبت نشده '
        },
      ],
      menu_FinancialManager: [
        {
          icon: 'mdi-account-multiple-plus',
          route: '/userlist',
          name: 'مدیریت کاربران ',
          nameM: 'کاربر '
        },
        {
          icon: 'mdi-toy-brick-plus-outline',
          route: '/InvoceAdd',
          name: ' ایجاد سفارش',
          nameM: 'سفارش '
        },
        {
          icon: 'mdi-briefcase-check',
          route: '/financialcheck',
          name: 'تایید مالی',
          nameM: 'مالی '
        },
        {
          icon: 'mdi-playlist-check',
          route: '/packingcheck',
          name: 'تایید بسته بندی',
          nameM: 'بسته بندی '
        },
        {
          icon: 'mdi-truck-check',
          route: '/SendingCheck',
          name: 'تایید ارسال',
          nameM: 'ارسال '
        },
        {
          icon: 'mdi-phone-check',
          route: '/deliverycheck',
          name: 'تایید تحویل مشتری',
          nameM: 'تحویل '
        },{
          icon: 'mdi-database-check',
          route: '/accountingcheck',
          name: 'تایید نهایی',
          nameM: 'نهایی '
        },
        {
          icon: 'mdi-toy-brick-search-outline',
          route: '/orderstate',
          name: 'وضعیت سفارش',
          nameM: 'وضعیت سفارش '
        },
      ],
      menu_MonitoringOperator: [
        {
          icon: 'mdi-toy-brick-search-outline',
          route: '/orderstate',
          name: 'وضعیت سفارش',
          nameM: 'وضعیت سفارش '
        },
        {
          icon: 'mdi-account-group',
          route: '/opratorreport',
          name: 'گزارش عملکرد اپراتورها',
          nameM: ' عملکرد اپراتورها'
        },
        {
          icon: 'mdi-tray-full',
          route: '/productreport',
          name: 'گزارش فروش محصولات',
          nameM: ' فروش محصولات'
        },
      ],
      menu_SocialAdmin: [
        {
          icon: 'mdi-toy-brick-plus-outline',
          route: '/InvoceAdd',
          name: ' ایجاد سفارش',
          nameM: 'سفارش '
        },
        {
          icon: 'mdi-toy-brick-remove',
          route: '/RejectedOrder',
          name: ' سفارش رد شده ',
          nameM: 'رد شده '
        },
        {
          icon: 'mdi-toy-brick-search-outline',
          route: '/orderstate',
          name: 'وضعیت سفارش',
          nameM: 'وضعیت سفارش '
        },
      ],
      menu_StockClerk: [
        {
          icon: 'mdi-playlist-check',
          route: '/packingcheck',
          name: 'تایید بسته بندی',
          nameM: 'بسته بندی '
        },
        {
          icon: 'mdi-truck-check',
          route: '/SendingCheck',
          name: 'تایید ارسال',
          nameM: 'ارسال '
        },
      ],
    };