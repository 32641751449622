import Vue from 'vue'
import jalaali from 'jalaali-js'
import * as moment from 'moment-timezone'

const numEnToFa = (input, def = '--') => {
  if (input === undefined || input == null) input = def
  let str1 = input.toString().trim()
  if (str1 === '') str1 = def
  str1 = str1.replace(/0/gim, '۰')
  str1 = str1.replace(/1/gim, '۱')
  str1 = str1.replace(/2/gim, '۲')
  str1 = str1.replace(/3/gim, '۳')
  str1 = str1.replace(/4/gim, '۴')
  str1 = str1.replace(/5/gim, '۵')
  str1 = str1.replace(/6/gim, '۶')
  str1 = str1.replace(/7/gim, '۷')
  str1 = str1.replace(/8/gim, '۸')
  str1 = str1.replace(/9/gim, '۹')
  return str1
}

const numberStyle = (num) => {
  const isM = num < 0
  num = Math.abs(num)
  let result = num
  if (num >= 1000000000) {
    result = comma((num / 1000000000).toFixed(2).replace(/\.0$/, '')) + 'B'
  }
  // ///
  else if (num >= 1000000) {
    result = comma((num / 1000000).toFixed(2).replace(/\.0$/, '')) + 'M'
  }
  // ///
  else if (num >= 1000) {
    result = comma((num / 1000).toFixed(2).replace(/\.0$/, '')) + 'K'
  }
  return numEnToFa(isM ? '-' + result : result)
}

const numberStyleOnePiont = (num, fixed = 1) => {
  const isM = num < 0
  num = Math.abs(num)
  let result = num
  if (num >= 1000000000) {
    result = comma((num / 1000000000).toFixed(fixed).replace(/\.0$/, '')) + 'B'
  }
  // ///
  else if (num >= 1000000) {
    result = comma((num / 1000000).toFixed(fixed).replace(/\.0$/, '')) + 'M'
  }
  // ///
  else if (num >= 1000) {
    result = comma((num / 1000).toFixed(fixed).replace(/\.0$/, '')) + 'K'
  }
  return numEnToFa(isM ? '-' + result : result)
}

const numberStyleNum = (num, fixed = 2) => {
  const isM = num < 0
  num = Math.abs(num)
  let result = num
  if (num >= 1000000000) {
    result = comma((num / 1000000000).toFixed(fixed).replace(/\.0$/, ''))
  }
  // ///
  else if (num >= 1000000) {
    result = comma((num / 1000000).toFixed(fixed).replace(/\.0$/, ''))
  }
  // ///
  else if (num >= 1000) {
    result = comma((num / 1000).toFixed(fixed).replace(/\.0$/, ''))
  }
  return numEnToFa(isM ? result + '-' : result)
}

const comma = (number) => {
  if (number === 0) return number
  if (!number) return
  number = number.toString().split(',').join('');
  return fatoEnNumber(number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
}

const priceFormat = (input) => {
  try {
    if (input) input = (input / 10).toFixed(2)
    return numEnToFa(input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
  } catch (e) {
    return input
  }
}

const priceFormatInt = (input) => {
  try {
    if (input) input = (input / 10).toFixed(0)
    return numEnToFa(input.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))
  } catch (e) {
    return input
  }
}

const percentGenEn = ({ to, yester }) => {
  if (to && yester) {
    if (parseFloat(to) > parseFloat(yester)) {
      return parseFloat(
        ((parseFloat(to) / parseFloat(yester) - 1) * 100).toFixed(2)
      )
    } else if (parseFloat(to) < parseFloat(yester)) {
      return -parseFloat(
        ((1 - parseFloat(to) / parseFloat(yester)) * 100).toFixed(2)
      )
    } else {
      return 0
    }
  } else {
    return 0
  }
}

const percentgen = ({ to, yester }) => {
  return numEnToFa(Math.abs(percentGenEn({ to, yester })))
}

const percentgenInt = (to, yester) => {
  if (parseFloat(to) > parseFloat(yester)) {
    return numEnToFa(
      parseFloat(
        (
          ((parseFloat(to) - parseFloat(yester)) / parseFloat(yester)) *
          100
        ).toFixed(0)
      )
    )
  } else if (parseFloat(to) < parseFloat(yester)) {
    return numEnToFa(
      parseFloat(
        (
          ((parseFloat(yester) - parseFloat(to)) / parseFloat(yester)) *
          100
        ).toFixed(0)
      )
    )
  } else {
    return numEnToFa(0)
  }
}

const fanumprice = (q) => {
  const test = q
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const persian = {
    0: '۰',
    1: '۱',
    2: '۲',
    3: '۳',
    4: '۴',
    5: '۵',
    6: '۶',
    7: '۷',
    8: '۸',
    9: '۹',
  }
  const string = (test + '').split('')
  const count = string.length
  let num
  for (let i = 0; i <= count; i++) {
    num = string[i]
    if (persian[num]) {
      string[i] = persian[num]
    }
  }
  return string.join('')
}

const famonth = (q) => {
  const t = new Date(q)
  const m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
  const ns = jalaali.toJalaali(m)
  return numEnToFa(ns.jy + '/' + ns.jm + '/' + ns.jd)
}

const famonthDay = (q) => {
  const t = new Date(q)
  //const m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
  const ns = jalaali.toJalaali(t)
  const format = 'HH:mm' 
  const timezone = 'Asia/Tehran'
  return (ns.jy + '/' + ns.jm + '/' + ns.jd + '--' + moment.utc(q).tz(timezone).format(format))
}
const famonthV2 = (q) => {
  if (q) {
    const t = new Date(q.replace(/-/g, '/'))
    let m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
    let s = m.toLocaleString('fa-IR')
    if (
      s.substring(5, 7) === '۱/' ||
      s.substring(5, 7) === '۲/' ||
      s.substring(5, 7) === '۳/' ||
      s.substring(5, 7) === '۴/' ||
      s.substring(5, 7) === '۵/' ||
      s.substring(5, 7) === '۶/'
    ) {
      m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
      s = m.toLocaleString('fa-IR')
    }
    const pm = {
      '۱/': ' فروردین',
      '۲/': ' اردیبهشت',
      '۳/': ' خرداد',
      '۴/': ' تیر',
      '۵/': ' مرداد',
      '۶/': ' شهریور',
      '۷/': ' مهر',
      '۸/': ' آبان',
      '۹/': ' آذر',
      '۱۰': ' دی',
      '۱۱': ' بهمن',
      '۱۲': ' اسفند',
    }
    const x = s.substring(5, 7)
    let w = s.substring(10, 18)
    if (s[17] === ':') w = s.substring(10, 17)
    if (s[16] === ':') w = s.substring(10, 16)
    if (s[12] === ':') w = s.substring(10, 15)

    if (s.substring(9, 10) === '،') {
      if (s.substring(7, 8) === '/') {
        return s.substring(8, 9) + pm[x] + ' ساعت' + w
      } else {
        return s.substring(7, 9) + pm[x] + ' ساعت' + w
      }
    } else if (s.substring(8, 9) === '،') {
      return s.substring(7, 8) + pm[x] + ' ساعت' + w
    } else return s.substring(8, 10) + pm[x] + ' ساعت' + w
  }
  return '--'
}

const famonthV3 = (q) => {
  const t = new Date(q)
  const m = new Date(t.getTime())
  const s = numEnToFa(m.toLocaleString('fa-IR'))
  if (s[8] === '،')
    if (s[16] === ':') return s.substring(11, 15)
    else return s.substring(11, 16)
  else if (s[16] === ':') return s.substring(11, 16)
  else if (s[17] === ':') return s.substring(11, 17)
  else return s.substring(11, 18)
}

const famonthV4 = (q) => {
  if (q) {
    const t = new Date(q.replace(/-/g, '/'))
    let m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
    let s = m.toLocaleString('fa-IR')
    if (
      s.substring(5, 7) === '۱/' ||
      s.substring(5, 7) === '۲/' ||
      s.substring(5, 7) === '۳/' ||
      s.substring(5, 7) === '۴/' ||
      s.substring(5, 7) === '۵/' ||
      s.substring(5, 7) === '۶/'
    ) {
      m = new Date(t.getTime() + 3.5 * 60 * 60 * 1000)
      s = m.toLocaleString('fa-IR')
    }
    // const pm = {
    //   '۱/': ' فروردین',
    //   '۲/': ' اردیبهشت',
    //   '۳/': ' خرداد',
    //   '۴/': ' تیر',
    //   '۵/': ' مرداد',
    //   '۶/': ' شهریور',
    //   '۷/': ' مهر',
    //   '۸/': ' آبان',
    //   '۹/': ' آذر',
    //   '۱۰': ' دی',
    //   '۱۱': ' بهمن',
    //   '۱۲': ' اسفند',
    // }
    // const x = s.substring(5, 7)
    let w = s.substring(10, 18)
    if (s[17] === ':') w = s.substring(10, 17)
    if (s[16] === ':') w = s.substring(10, 16)
    if (s[12] === ':') w = s.substring(10, 15)

    if (s.substring(9, 10) === '،') {
      if (s.substring(7, 8) === '/') {
        return w
      } else {
        return w
      }
    } else if (s.substring(8, 9) === '،') {
      return w
    } else return w
  }
  return '--'
}

const createdAtToFormat = (q, format = 'jYYYY/jM/jD -- HH:mm' , timezone = 'Asia/Tehran') => {
  if (q) {
    // return moment.utc(q).tz(timezone).format(format)
    return moment.utc(q).tz(timezone).format(format)
  }
  return '--'
}

const fanumcomma = (q) => {
  const test = q
    .toString()
    .replace(/\D/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const persian = {
    0: '۰',
    1: '۱',
    2: '۲',
    3: '۳',
    4: '۴',
    5: '۵',
    6: '۶',
    7: '۷',
    8: '۸',
    9: '۹',
  }
  const string = (test + '').split('')
  const count = string.length
  let num
  for (let i = 0; i <= count; i++) {
    num = string[i]
    if (persian[num]) {
      string[i] = persian[num]
    }
  }
  return string.join('')
}

const fanumVolume = (q) => {
  if (Math.abs(q) < 1000) {
    const persian = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string = (q + '').split('')
    const count = string.length
    let num
    for (let i = 0; i <= count; i++) {
      num = string[i]
      if (persian[num]) {
        string[i] = persian[num]
      }
    }
    return string.join('')
  }
  if (Math.abs(q) >= 1000 && Math.abs(q) < 1000000) {
    q = (q / 1000).toFixed(0)
    const persian1 = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string1 = (q + '').split('')
    const count1 = string1.length
    let num1
    for (let i1 = 0; i1 <= count1; i1++) {
      num1 = string1[i1]
      if (persian1[num1]) {
        string1[i1] = persian1[num1]
      }
    }
    return string1.join('') + 'K'
  }
  if (Math.abs(q) >= 1000000) {
    q = (q / 1000000).toFixed(0)
    const persian2 = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string2 = (q + '').split('')
    const count2 = string2.length
    let num2
    for (let i2 = 0; i2 <= count2; i2++) {
      num2 = string2[i2]
      if (persian2[num2]) {
        string2[i2] = persian2[num2]
      }
    }
    return string2.join('') + 'M'
  }
  return '--'
}

const fanumVolumeV2 = (q) => {
  if (Math.abs(q) < 1000) {
    const persian = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string = (q + '').split('')
    const count = string.length
    let num
    for (let i = 0; i <= count; i++) {
      num = string[i]
      if (persian[num]) {
        string[i] = persian[num]
      }
    }
    return string.join('')
  }
  if (Math.abs(q) >= 1000 && Math.abs(q) < 1000000) {
    q = (q / 1000).toFixed(2)
    const persian1 = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string1 = (q + '').split('')
    const count1 = string1.length
    let num1
    for (let i1 = 0; i1 <= count1; i1++) {
      num1 = string1[i1]
      if (persian1[num1]) {
        string1[i1] = persian1[num1]
      }
    }
    return string1.join('') + 'K'
  }
  if (Math.abs(q) >= 1000000) {
    q = (q / 1000000).toFixed(2)
    const persian2 = {
      0: '۰',
      1: '۱',
      2: '۲',
      3: '۳',
      4: '۴',
      5: '۵',
      6: '۶',
      7: '۷',
      8: '۸',
      9: '۹',
    }
    const string2 = (q + '').split('')
    const count2 = string2.length
    let num2
    for (let i2 = 0; i2 <= count2; i2++) {
      num2 = string2[i2]
      if (persian2[num2]) {
        string2[i2] = persian2[num2]
      }
    }
    return string2.join('') + 'M'
  }
  return '--'
}

const MobileFilter = (q) => {
  let x = q.slice(3, q)
  x = '0' + x
  return x
}

const NegetiveZero = (q) => {
  if (q < 0) {
    return 0
  } else {
    return q
  }
}

const fatoEnNumber = (input) => {
  if (input === undefined || input == null) return ''
      let str1 = input.toString().trim()
      if (str1 === '') return ''
      str1 = str1.replace(/۰/gim, '0')
      str1 = str1.replace(/۱/gim, '1')
      str1 = str1.replace(/۲/gim, '2')
      str1 = str1.replace(/۳/gim, '3')
      str1 = str1.replace(/۴/gim, '4')
      str1 = str1.replace(/۵/gim, '5')
      str1 = str1.replace(/۶/gim, '6')
      str1 = str1.replace(/۷/gim, '7')
      str1 = str1.replace(/۸/gim, '8')
      str1 = str1.replace(/۹/gim, '9')
      return str1
}


const faSpaceNumber = (input) => {
  if (input === undefined || input == null) return ''
  return input.replace(/[^0-9](?=[0-9])/g, ' $& ')
}

Vue.filter('numEnToFa', numEnToFa)
Vue.filter('numberStyle', numberStyle)
Vue.filter('numberStyleOnePiont', numberStyleOnePiont)
Vue.filter('numberStyleNum', numberStyleNum)
Vue.filter('fanum', numEnToFa)
Vue.filter('comma', comma)
Vue.filter('priceFormat', priceFormat)
Vue.filter('priceFormatInt', priceFormatInt)
Vue.filter('percentgen', percentgen)
Vue.filter('percentGenEn', percentGenEn)
Vue.filter('percentgenInt', percentgenInt)
Vue.filter('fanumprice', fanumprice)
Vue.filter('famonth', famonth)
Vue.filter('famonthDay', famonthDay)
Vue.filter('famonthV2', famonthV2)
Vue.filter('famonthV3', famonthV3)
Vue.filter('famonthV4', famonthV4)
Vue.filter('fanumcomma', fanumcomma)
Vue.filter('fanumVolume', fanumVolume)
Vue.filter('fanumVolumeV2', fanumVolumeV2)
Vue.filter('NegetiveZero', NegetiveZero)
Vue.filter('createdAtToFormat', createdAtToFormat)
Vue.filter('MobileFilter', MobileFilter)
Vue.filter('fatoEnNumber', fatoEnNumber)
Vue.filter('faSpaceNumber', faSpaceNumber)